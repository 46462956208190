
/* Layout */
$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* Tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
*/

/* primeng */
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";

// import Quill styles in styles.scss
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
